jQuery(document).ready(function($){
	responsive.init();

	// gdprSubscribeModal(formID, privacyPolicyURL, linkColour, buttonTextColour);


	// Home hero carousel
	if($('#home .section-hero ul.list-hero li').length > 0) {
		// Initialise slick
		$('.section-hero ul.list-hero').slick({
			dots: true,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			fade: false,
			arrows: false
		});
	}

	// home testimonial carousel
	if($('#home .section-testimonial ul.list-testimonial li').length > 0) {
		// Initialise slick
		$('.section-testimonial ul.list-testimonial').slick({
			dots: true,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			fade: false,
			arrows: false
		});
	}

	// About team view more
	if($('#about .section-team ul.list-team li').length > 0) {
		// On button click
		$('ul.list-team li .btn.mod-more').click(function (e) {
			// Just in case
			e.preventDefault();
			// Check text value & change accordingly
			$(this).text($(this).text().toLowerCase() === 'more' ? 'less' : 'more');
			// Toggle copy mod-more class
			$(this).prev('.sub-copy').toggleClass('mod-more');
		});
	}

	$('.sub-mobile-menu').click(function() {
		$(this).toggleClass('active');
		$('#main-nav').toggleClass('active');
	});

	// Open awards modal
	$('.img-award').click(function() {
        $('.section-award-modal').addClass('mod-show');
        $('.sub-modal-background').addClass('mod-show');
    })

	// Close awards modal
	$('.close-modal-button').click(function() {
        $('.section-award-modal').removeClass('mod-show');
        $('.sub-modal-background').removeClass('mod-show');
    })
});


// This automatically adds 'data-sitekey' to the buttons.
// and programmically binds a callback for captcha completion.
// add '.captcha-ignore' as a class on the button to ignore
// the button and disable captcha on that form.
// var CaptchaCallback = function(){
// 	var SITE_KEY = '__SITEKEY__';
//
// 	// Get all form elements on page.
// 	var pageForms = $('form');
//
// 	// Ensure the page has a form on it, at least.
// 	if(pageForms.length <= 0) {
// 		return;
// 	}
//
//
// 	pageForms.each(function(formIncId, form){
// 		// Grab all of the 'submit' buttons which are attached to the form.
// 		var submissionButtons = $(form).find('input[type="submit"]:not(.captcha-ignore)');
//
// 		if(submissionButtons.length <= 0){
// 			return; // Return here is similar to 'continue' within a loop.
// 		}
//
// 		submissionButtons.each(function(btnIncId, button){
// 			$(button).attr('data-sitekey', SITE_KEY);
//
// 			grecaptcha.render(button, {
// 				sitekey: SITE_KEY,
// 				callback: function(){
// 					$(button).closest('form').submit();
// 				}
// 			});
// 		});
// 	});
// };