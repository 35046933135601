// Responsive functions
(function (window, $) {
	var mqTypes = [
		'mobile',
		'desktop',
		'tablet',
		'tablet-down',
		'tablet-up',
		'landscape',
		'portrait',
		'retina',
	];

	var mqTable = {};
	var lastStates = [];

	window.responsive = {
		init: init,
		stateEnabledEvent: stateEnabledEvent,
		stateDisabledEvent: stateDisabledEvent,
		currentStates: [],
	};

	function stateEnabledEvent(mq) {
		return 'ResponsiveLoad--' + mq;
	}

	function stateDisabledEvent(mq) {
		return 'ResponsiveUnload--' + mq;
	}

	function init() {
		var head = $('head');

		var fireResponsiveEvent = function(name) {
			return function(mq) {
				var eventName = '';

				if (mq.matches) {
					eventName = stateEnabledEvent(name);
					window.responsive.currentStates.push(name);

				} else {
					eventName = stateDisabledEvent(name);
					window.responsive.currentStates = window.responsive.currentStates.filter(function(value) {
						return value !== name;
					});
				}

				window.dispatchEvent(new CustomEvent(eventName));
			};
		};

		// set up media query
		mqTypes.forEach(function (mq) {
			// Add a meta tag whose content rule is the media query to fetch it
			var mqRule = head.append('<meta class="mq-'+mq+'">').find('.mq-'+mq).css('content').replace(/['"]/g, '');
			var mqListener = window.matchMedia(mqRule);
			mqListener.addListener(fireResponsiveEvent(mq));
			mqTable[mq] = mqListener;
		});

		mqTypes.forEach(function (mq) {
			if (mqTable[mq].matches) {
				fireResponsiveEvent(mq)(mqTable[mq]);
			}
		});
	}
}(window, jQuery));