// This file is included before any vendor scripts, so you can configure them here

window.debounce = function (func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

window.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

(function() {
	// animFrameThrottle - cap resize events to reqAnimFram for better performance
	var animFrameThrottle = function(type, name, obj, throttle) {
		obj = obj || window;
		var running = false;
		var func;
		if (window.isIE11) {
			func = function() {
				if (running) { return; }
				running = true;
				requestAnimationFrame(function() {
					var event = document.createEvent("Event");
					event.initEvent(name, false, true);
					obj.dispatchEvent(event);
					running = false;
				});
			};
		} else {
			func = function() {
				if (running) { return; }
				running = true;
				requestAnimationFrame(function() {
					obj.dispatchEvent(new CustomEvent(name));
					running = false;
				});
			};
		}
		if (throttle) {
			obj.addEventListener(type, window.debounce(func, 50));
		} else {
			obj.addEventListener(type, func);
		}
	};

	/* init - you can init any event */
	animFrameThrottle("resize", "optimizedResize");
	animFrameThrottle("scroll", "optimizedScroll", window, true);
})();